import React, { useEffect, useState, useCallback } from 'react';
import { getService } from 'reactInAngular';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {getENPSFeedback} from '../../../../shared/services/migrated/enps.service';
import CommentTypeHandler from '../../../../shared/new-components/hf-comments/comment-type-handler/CommentTypeHandler';
import moment from 'moment';
import Dates from '../../../../utilities/date.utilities';
import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import {FilterSelected} from '../../../../shared/new-components/hf-filter-menu/types';
import {toSegmentParams} from '../../shared/utils';
import {DateGrouping} from '../../../../shared/types/common';
import EngagementFeedbackFilters from './EngagementFeedbackFilters';



interface EngagementScoresFeedbackBoxProps {
    dateGrouping: DateGrouping;
    to: Date;
    filters?: FilterSelected;
    scoreId?: string;
    mode: 'ENPS' | 'SCORE' | 'HI' | 'ALL';
    hasEnoughActiveEmployees: boolean;
}

const EngagementScoresFeedbackBox: React.FC<EngagementScoresFeedbackBoxProps> = ({
    dateGrouping,
    to,
    filters: externalFilters,
    scoreId,
    mode,
    hasEnoughActiveEmployees= true,
}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalComments, setTotalComments] = useState<number>(0);
    const [itemsPerPage] = useState<number>(5);
    const [comments, setComments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [from, setFrom] = useState<Date | null>(null);
    
    const baseFields = {
        hasConversation: ['ALL'],
        positivenessFrom: null,
        positivenessTo: null,
        enpsClassification: null
    };

    const [filters, setFiltersBase] = useState<FilterSelected>({
        ...baseFields,
        ...externalFilters
    });

    const setFilters = (newFilters: FilterSelected) => {
        setFiltersBase({
            ...baseFields,
            ...newFilters
        });
    };

    useEffect(() => {
        setFilters({
            hasConversation: filters.hasConversation,
            positivenessFrom: filters.positivenessFrom,
            positivenessTo: filters.positivenessTo,
            enpsClassification: filters.enpsClassification,
            ...externalFilters
        });
    }, [externalFilters]);

    const $translate = getService('$translate');
    const Scores = getService('Scores');
    const Comments = getService('Comments');


    const prepareParams = () => {
        const params: any = {};

        if (filters?.segmentIds) {
            params.filters = toSegmentParams(filters.segmentIds as any[]);
        }
        if (filters?.groupIds) {
            params.groupId = filters.groupIds;
        }
        if (filters?.hierarchyIds) {
            params.hierarchyId = filters.hierarchyIds;
        }
        if (from) {
            params.from = from;
        }
        if (to) {
            params.to = Dates.lastDayOfMonth(to);
        }
        if (filters.hasConversation?.[0] !== 'ALL') {
            params.hasConversation = filters.hasConversation?.[0] === 'YES';
        }
        if (filters.positivenessFrom) {
            params.positivenessFrom = filters.positivenessFrom;
        }
        if (filters.positivenessTo) {
            params.positivenessTo = filters.positivenessTo;
        }
        if (mode === 'ENPS' && filters.enpsClassification?.[0]) {
            params.eNPSClassification = filters.enpsClassification?.[0];
        }
        params.feedbackTypes = [];
        if (scoreId) {
            params.scoreId = scoreId;
        }

        params.fromComment = ((currentPage - 1) * 5);
        params.toComment =  5;

        return params;
    };


    const loadComments = useCallback(() => {
        if (to && from) {
            setLoading(true);

            const params = prepareParams();

            if (mode === 'SCORE') {
                Scores.feedback.get(params, (err: any, scoreComments: any) => {
                    if (!err) {
                        setTotalComments(scoreComments.total);
                        setComments(scoreComments.result);
                    }
                    setLoading(false);
                });
            } else if (mode === 'ENPS') {
                getENPSFeedback(params, (err: any, enpsComments: any) => {
                    if (!err) {
                        setTotalComments(enpsComments.total);
                        setComments(enpsComments.result);
                    }
                    setLoading(false);
                });
            } else if (mode === 'HI') {
                params.feedbackTypes = ['CLASSIFICATION_FOLLOWUP'];
                Comments.get(params, (feedbackComments: any) => {
                    setTotalComments(feedbackComments.total);
                    setComments(feedbackComments.comments);
                    setLoading(false);
                });
            } else {
                params.feedbackTypes = ['SCORE', 'ENPS', 'CLASSIFICATION_FOLLOWUP'];
                Comments.get(params, (feedbackComments: any) => {
                    setTotalComments(feedbackComments.total);
                    setComments(feedbackComments.comments);
                    setLoading(false);
                });
            }
        }
    }, [from, to, filters, scoreId, mode, currentPage, itemsPerPage]);

    useEffect(() => {
        if (to) {
            if (dateGrouping) {
                if (dateGrouping === DateGrouping.MONTH) {
                    setFrom(Dates.firstDayOfMonth(to));
                } else if (dateGrouping === DateGrouping.QUARTER) {
                    setFrom(Dates.firstDayOfQuarter(to));
                } else if (dateGrouping === DateGrouping.YEAR) {
                    setFrom( Dates.substractDoDate(to, 1, 'year'));
                    console.log('from', from);
                    console.log('to', to);
                }
            } else {
                setFrom(Dates.firstDayOfMonth(to));
            }
        }
    }, [to, dateGrouping]);

    const onCommentUpdated = (err, updatedCommentId: string) => {
        Comments.getOne(updatedCommentId, (err: any, updatedComment: any) => {
            if (!err) {
                setComments((prevComments) =>
                    prevComments.map((comment) =>
                        comment.id === updatedCommentId ? updatedComment : comment
                    )
                );
            }
        });
    };

    useEffect(() => {
        loadComments();
    }, [to, filters, scoreId, mode, loadComments]);


    return (
        <Stack
            direction='column'
            gap={2}
            height={'100%'}
        >
            <Stack direction={'column'}>
                <Typography variant='h4' gutterBottom>
                    {$translate.instant('ENGAGEMENT_SCORES_FEEDBACK')}
                </Typography>
                {from ? (
                    <Typography variant='caption' color='textSecondary'>
                        {$translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF_PERIOD', {
                            start: moment(from).utc().format('MMM YYYY'),
                            end: moment(to).format('MMM YYYY'),
                        })}
                    </Typography>
                ) : (
                    <Typography variant='caption' color='textSecondary'>
                        {$translate.instant('ENGAGEMENT_SCORES_SUMMARY_AS_OF', {
                            days: moment(to).format('MMM YYYY'),
                        })}
                    </Typography>
                )}
            </Stack>

            {hasEnoughActiveEmployees && (
                <>
                    <Stack direction={'column'} gap={2}>
                        <EngagementFeedbackFilters
                            filters={filters}
                            onFiltersChange={setFilters}
                            mode={mode}
                        />

                        <HappyLoading loading={loading}>

                            {totalComments === 0 ? (
                                <Box>
                                    <EmptyState image={'images/no_feedback_engagement.svg'}
                                        message={$translate.instant('ENGAGEMENT_NO_FEEDBACK')}
                                        submessage={$translate.instant('ENGAGEMENT_NO_FEEDBACK_SUBMESSAGE')}
                                        small={true}></EmptyState>
                                </Box>
                            )
                                : (
                                    <Stack>
                                        <Typography variant='body3' fontWeight={'bold'}>
                                            {$translate.instant('TOTAL_COMMENTS')} {totalComments}
                                        </Typography>
                                        <Box mt={2}>
                                            {comments.map((comment) => (
                                                <CommentTypeHandler key={comment.id} comment={comment} onCommentUpdated={onCommentUpdated}/>
                                            ))}
                                        </Box>

                                    </Stack>
                                )}


                        </HappyLoading>
                    </Stack>

                    <Box mt={2} display='flex' justifyContent='center'>
                        <Pagination
                            count={Math.ceil(totalComments / itemsPerPage)}
                            page={currentPage}
                            onChange={(event, page) => setCurrentPage(page)}
                            size='small'
                            siblingCount={1}
                            boundaryCount={1}
                            color='primary'
                        />
                    </Box>
                </>

            )}

            {!hasEnoughActiveEmployees && (
                <EmptyState
                    small={true}
                    image='images/anonymous.png'
                    message={$translate.instant('NOT_ENOUGH_EMPLOYEES', {})}
                    submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE', {})}
                />
            )}


        </Stack>
    );
};

export default EngagementScoresFeedbackBox;
